import React from 'react';
import Button from '../button/button';
import Resume from '../../assets/DanielNordby.pdf';
import './about.scss';

class About extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lead: "I'm a full-stack developer who specializes in Shopify ecommerce.",
      content: "I specialize in front-end work, but have architected and built end-to-end solutions for many unique client needs."
    }
  }

  render() {
    const {state} = this;
    const {lead, content} = state;

    return (
      <section className="About" id="about">
        <h2 className="About__title sectionTitle h1">About</h2>
        <h2 className="About__lead">{lead}</h2>

        <div className="About__content">
          <p>{content}</p>
          <Button link={Resume} type="link" target="_blank" content="VIEW RESUME"/>
        </div>
      </section>
    );
  }
}

export default About;
