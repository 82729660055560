import React from 'react';
import './header.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      nav: ['About', 'Skills', 'Work', 'Contact']
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  render() {
    const {state} = this;
    const toggleStateClass = this.state.open ? 'open' : 'closed';
    
    return (
      <header className={`Header ${toggleStateClass}`}>
        <div className="Header__drawer">
          <button type="button" className="Header__drawer-toggle" onClick={this.toggleMenu} title="Toggle menu">
            <Lines />
          </button>

          <nav className="Header__drawer-nav">
            <ul>
              {state.nav.map((item) => {
                return(
                  <li key={item.toLowerCase()}><p>{item.toUpperCase()}</p></li>
                );
              })}
            </ul>
          </nav>

          <p className="Header__drawer-logo">D<span>aniel</span>N<span>ordby</span></p>
        </div>

        <div className="Header__background" onClick={this.toggleMenu}></div>
      </header>
    );
  }

  toggleMenu() {
    this.setState((state) => ({
      open: !state.open
    }));
  }
}

function Lines() {
  return [1,2,3].map((index) => <div className="line" key={index.toString()}></div>);
}

export default Header;
