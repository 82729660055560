import React from 'react';
import './work.scss';
import oomph from '../../assets/oomph.svg'
import oomphImg from '../../assets/oomph.png'
import maraHoffman from '../../assets/maraHoffman.svg'
import maraHoffmanImg from '../../assets/maraHoffman.jpg'
import blunt from '../../assets/blunt.svg'
import bluntImg from '../../assets/blunt.png'

class Work extends React.Component {
  constructor() {
    super();

    this.work = [
      {
        lifestyle: oomphImg,
        logo: oomph,
        title: "oomph",
        blurb: "Highly configurable custom furniture.",
        solutions: ["100-variant limit solution", "Dynamic theme settings", "Expanded product enrichment layer", "Custom Shopify theme"],
        link: "http://oomphhome.com/"
      },
      {
        lifestyle: bluntImg,
        logo: blunt,
        title: "Blunt Umbrellas",
        blurb: "Fashionable umbrellas with a focus on quality.",
        solutions: ["Dynamic, weather-driven CTA module", "Scaled product imagery to indicate scale", "Customer-driven lifestyle imagery experience", "Custom Shopify theme"],
        link: "https://bluntumbrellas.com/"
      },
      {
        lifestyle: maraHoffmanImg,
        logo: maraHoffman,
        title: "Mara Hoffman",
        blurb: "Luxury fashion brand focused on sustainability.",
        solutions: ["Custom filtering", "100-variant limit solution", "Expanded product enrichment layer", "Custom Shopify theme"],
        link: "https://marahoffman.com/"
      }
    ]
    
  }

  render() {
    const {work} = this;

    return(
      <section className="Work" id="work">
        <h2 className="Work__title sectionTitle h1">Work</h2>

        {work.map((job) => {
          return (
            <div className="Work__job" key={job.title.toLowerCase()}>
              <a href={job.link}>
                <img src={job.lifestyle} alt={`${job.title} lifestyle`}/>
              </a>

              <div className="Work__job-description">
                <a href={job.link}>
                  <img src={job.logo} className="Work__job-logo" alt={job.title} />
                </a>
                <p className="Work__job-detail">{job.blurb}</p>
                <ul>
                  {job.solutions.map((item) => {
                    return (
                      <li key={item.toLowerCase().replace(' ', '-')}>{item}</li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )
        })}
      </section>
    );
  }
}

export default Work;
