import React from 'react';
import './skills.scss';

class Skills extends React.Component {
  constructor() {
    super();

    this.state = {
      platforms: [
        "Shopify",
        "Shopify APIs",
        "Wordpress",
        "Airtable",
        "Prismic",
        "Vercel",
      ],
      languages: [
        "HTML",
        "CSS/SCSS",
        "JavaScript/jQuery",
        "Liquid",
        "GraphQL",
        "PHP",
        "Ruby",
      ],
      frameworks: ["Vue.js", "Ruby on Rails"],
      learning: ["React", "Next.js", "Storybook.js"]
    }
  }

  render() {
    const {state} = this;

    return(
      <section className="Skills" id="skills">
        <SkillSvg />
        <h2 className="Skills__title sectionTitle h1">Skills</h2>

        <div className="Skills__wrapper">
          <h3>Languages</h3>
          {state.languages.map((item) => {
            return(
              <p className="Skills__wrapper-skill" key={item.toLowerCase()}>{item}</p>
            );
          })}
        </div>

        <div className="Skills__wrapper">
          <h3>Platforms/APIs</h3>
          {state.platforms.map((item) => {
            return(
              <p className="Skills__wrapper-skill" key={item.toLowerCase()}>{item}</p>
            );
          })}
        </div>

        <div className="Skills__wrapper">
          <h3>Frameworks</h3>
          {state.frameworks.map((item) => {
            return(
              <p className="Skills__wrapper-skill" key={item.toLowerCase()}>{item}</p>
            );
          })}
        </div>

        <div className="Skills__wrapper">
          <h3>Learning-By-Building</h3>
          {state.learning.map((item) => {
            return(
              <p className="Skills__wrapper-skill" key={item.toLowerCase()}>{item}</p>
            );
          })}
        </div>
      </section>
    );
  }
}



function SkillSvg() {
  return(
    <svg className="Skills__svg" viewBox="0 0 23.075 22.513" fillRule="evenodd" textRendering="geometricPrecision" imageRendering="optimizeQuality" clipRule="evenodd" shapeRendering="geometricPrecision"><path d="M1.021 9.644l1.36.935 4.807-4.584-2.215-1.7-3.952 5.349zm14.538-3.91l1.323-3.26-6.56-1.567-.653 3.505 2.926 1.657 2.964-.334zm-6.3-.615l-.987.892h.016l.653.504 2.236-.26L9.26 5.12zM5.495 3.886l2.199 1.626 1.174-1.084.669-3.187-4.042 2.645zm-2.645 7.288l1.657 3.521 2.815-1.338L8.495 7.02 7.82 6.5 2.85 11.174zm.35 4.828l2.614 4.642.164-.988-1.88-4.084-.898.43zm3.49 3.968l-.207 1.36 6.432.377.112-2.258-6.337.52zm-3.787-4.563l.898-.409-1.663-3.676-.913-.595 1.678 4.68zm1.848-.149l1.843 3.968 6.47-.558.111-1.508-5.555-3.246-2.869 1.344zM17.142 9.91l-.132-.378-4.86-2.735-2.964.35-1.174 6.21 5.46 3.187L16.23 14.1l.913-4.19zm-1.1 5.2l-2.198 2.013-.112 1.487 1.62-.165.69-3.336zm-.371 4.095l-1.976.191-.074 2.178 4.546-1.063-2.496-1.306zm2.364-9.598l-.112.504.914 2.014 3.43 1.232-.467-5.593-3.765 1.843zm-.617-6.746l-1.36 3.15 1.233 2.725.335.223 3.914-1.902-4.122-4.196zm5.593 11.112l-3.617 6.948-6.055 1.53-7.51-.43-3.485-6.056L.071 9.687l4.562-6.098 5.37-3.522 7.511 1.77 5.014 5.2.483 6.936zm-5.964.298l-.914 4.34 2.948 1.529 3.091-6.018-3.484-1.365-1.641 1.514zm.69-3.134l-.483 2.05 1.004-.876-.52-1.174zm-2.14-4.77l-2.013.281 2.852 1.583-.84-1.864z" fill="#ffffff"/></svg>
  );
}

export default Skills;
