import React from 'react';
import './button.scss';

class Button extends React.Component {
  render() {
    const {props} = this;
    const {type, link, classes, target, content} = props;

    if (type === 'link') {
      return (
        <a href={link} className={`Btn ${classes}`} target={target}>{content}</a>
      )
    }

    return (
      <button type={type} className={`Btn ${classes}`}>{content}</button>
    ); 
  }
}

export default Button;
