// base //
import React from 'react';
import './App.scss';

// components //
import Header from '../components/header/header';
import Hero from '../components/homepageHero/hero';
import About from '../components/homepageAbout/about';
import Skills from '../components/homepageSkills/skills';
import Work from '../components/homepageWork/work';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }


  render() {
    return (
      <div className="App">
        <Header />

        <div className="Content">
          <Hero />
          <About />
          <Skills />
          <Work />
        </div>
      </div>
    );
  }
}

export default App;
