import React from 'react';
import './hero.scss';
import heroBackground from '../../assets/intro2.jpg';

class Hero extends React.Component {
  constructor() {
    super();

    this.state = {
      blink: false,
      showArrow: false,
      activeKeyword: '',
      background: heroBackground,
      keywords: ["a developer.", "a strategist.", "a problem solver.", "Daniel."],
      subheader: "Full-stack developer, Shopify expert, client solutions strategist, and team leader."
    }

    this.toggleBlink = this.toggleBlink.bind(this);
    this.toggleKeywords = this.toggleKeywords.bind(this);
    this.toggleArrow = this.toggleArrow.bind(this);
  }

  render() {
    const {state} = this;
    const {blink, activeKeyword, showArrow, subheader, background} = state;
    const style = {
      backgroundImage: `url(${background})`
    }

    return (
      <section className="Hero" style={style}>
        <div className="Hero__mask"></div>

        <div className="Hero__wrapper">
          <p className="Hero__keywords h1">I'm&nbsp;
            <span className="Hero__keywords-word">{activeKeyword}</span>
            <span className={`Hero__cursor ${blink ? "show" : "hide"}`}></span>
          </p>
          <p className={`Hero__subheader ${showArrow ? 'shown' : ''}`}>{subheader}</p>
        </div>

        <div className="Hero__arrow">
          <svg className={`${showArrow ? 'shown' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M48.707 19.353l-1.414-1.413L25 40.232 2.707 17.94l-1.414 1.413L25 43.06z"/>
            <path d="M48.707 8.353L47.293 6.94 25 29.232 2.707 6.94 1.293 8.353 25 32.06z"/>
          </svg>
        </div>
      </section>
    );
  }

  componentDidMount() {
    this.toggleBlink();
    this.toggleKeywords();
    this.toggleArrow();
  }

  toggleArrow() {
    const {state} = this;
    const {showArrow} = state;
    const totalDelay = state.keywords.length * 2300;

    setTimeout(() => {
      this.setState({showArrow: !showArrow});
    }, totalDelay);
  }

  toggleBlink() {
      const {blink} = this.state;
      const timer = blink ? 600 : 400 ;

      setTimeout(() => {
        this.setState({blink: !blink});
        this.toggleBlink();
      }, timer);
  }

  toggleKeywords() {
    let letterDelay = 100;
    let wordDelay = 600;
    const {keywords} = this.state;

    keywords.map((word) => {
      setTimeout(() => {
        let letterIndex = 0;
        let letterString = '';
        const letters = word.split('');
    
        letters.map((letter) => {
          const wordLength = letters.length;

          setTimeout(() => {
            if (letterIndex < wordLength) {
              letterString += letter;
              letterIndex++;
            }

            this.setState({activeKeyword: letterString});
          }, letterDelay);
          letterDelay += 80;

          return true;
        });
    
        return true;
      }, wordDelay);
      wordDelay += 1500;

      return true;
    })
  }
}

export default Hero;
